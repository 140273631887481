 <template>
  <SelectExtended
    id="displayOptionsSelection"
    v-model="displayOptionsSelection"
    label="Paramétrage de l’affichage du plan"
    multiple
    option-key="key"
    option-value="value"
    :items="[
      { value: 'Position géographique', key: 'displayGeo' },
      { value: 'Numéro de répétition + numéro de modalité', key: 'displayComputedId' },
      { value: 'Numéro du bloc', key: 'displayRepetition' },
      { value: 'Numéro de modalité', key: 'displayModalityNumber' },
      { value: 'Désignation modalité', key: 'displayDesignation' },
      { value: 'Numéro de ligne et colonne', key: 'displayHeaders' },
      { value: 'Couleurs', key: 'displayColors' },
    ]"
  />
</template>
<script>
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'DisplayPlanOptions',
  components: {
    SelectExtended,
  },
  props: {
    planId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      displayOptionsSelection: [],
      displayOptions: {
        id: null,
        displayGeo: true,
        displayComputedId: true,
        displayRepetition: true,
        displayModalityNumber: true,
        displayDesignation: true,
        displayHeaders: true,
        displayColors: true,
      },
    }
  },
  emits: ['display-plan-options'],
  watch: {
    displayOptionsSelection() {
      Object.entries(this.displayOptions).forEach(([key]) => {
        const resultat = this.displayOptionsSelection.find(
          (option) => option.key === key,
        )
        if (key !== 'id') {
          if (resultat !== undefined) {
            this.displayOptions[key] = true
          } else {
            this.displayOptions[key] = false
          }
        }
      })
      this.storeDisplayPlanOptions(this.displayOptions)
    },
  },
  mounted() {
    this.displayOptionsSelection = [
      { value: 'Position géographique', key: 'displayGeo' },
      { value: 'Numéro de répétition + numéro de modalité', key: 'displayComputedId' },
      { value: 'Numéro du bloc', key: 'displayRepetition' },
      { value: 'Numéro de modalité', key: 'displayModalityNumber' },
      { value: 'Désignation modalité', key: 'displayDesignation' },
      { value: 'Numéro de ligne et colonne', key: 'displayHeaders' },
      { value: 'Couleurs', key: 'displayColors' },
    ]

    this.setDisplayData()
    this.$emit('display-plan-options', this.displayOptions)
  },
  methods: {
    storeDisplayPlanOptions(displayOptions) {
      let displayPlanOptionsArray = []
      const displayPlanOptions = this.helperService.cloneObject(displayOptions)

      if (localStorage.getItem('displayPlanOptions')) {
        const localDisplayPlanOptions = JSON.parse(
          localStorage.getItem('displayPlanOptions'),
        )
        const found = localDisplayPlanOptions.find(
          (element) => element.id === this.planId,
        )
        const index = localDisplayPlanOptions.indexOf(found)
        if (index > -1) {
          localDisplayPlanOptions.splice(index, 1)
        }

        displayPlanOptionsArray = localDisplayPlanOptions
      }
      displayPlanOptions.id = this.planId
      displayPlanOptionsArray.push(displayPlanOptions)

      localStorage.setItem(
        'displayPlanOptions',
        JSON.stringify(displayPlanOptionsArray),
      )
      this.$emit('display-plan-options', this.displayOptions)
    },
    setDisplayData() {
      if (localStorage.getItem('displayPlanOptions')) {
        const localDisplayPlanOptions = JSON.parse(
          localStorage.getItem('displayPlanOptions'),
        )
        const found = localDisplayPlanOptions.find(
          (element) => element.id === this.planId,
        )
        const localDisplayOptionsSelection = []
        if (found) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(found)) {
            if (key === 'displayGeo' && value) {
              localDisplayOptionsSelection.push({
                value: 'Position géographique',
                key: 'displayGeo',
              })
            }
            if (key === 'displayComputedId' && value) {
              localDisplayOptionsSelection.push({
                value: 'Numéro de répétition + numéro de modalité',
                key: 'displayComputedId',
              })
            }
            if (key === 'displayRepetition' && value) {
              localDisplayOptionsSelection.push({
                value: 'Numéro du bloc',
                key: 'displayRepetition',
              })
            }
            if (key === 'displayModalityNumber' && value) {
              localDisplayOptionsSelection.push({
                value: 'Numéro de modalité',
                key: 'displayModalityNumber',
              })
            }
            if (key === 'displayDesignation' && value) {
              localDisplayOptionsSelection.push({
                value: 'Désignation modalité',
                key: 'displayDesignation',
              })
            }
            if (key === 'displayHeaders' && value) {
              localDisplayOptionsSelection.push({
                value: 'Numéro de ligne et colonne',
                key: 'displayHeaders',
              })
            }
            if (key === 'displayColors' && value) {
              localDisplayOptionsSelection.push({
                value: 'Couleurs',
                key: 'displayColors',
              })
            }
          }
          this.displayOptionsSelection = localDisplayOptionsSelection
        }
      }
    },
  },
}
</script>
