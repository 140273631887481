<template>
  <template v-if="!isLoading">
    <SelectExtended
      v-if="displayModalitiesOptions.length > 0 || (fromParent && displayParentModalitiesOptions.length > 0)"
      id="displayModalitiesOptionsSelection"
      v-model="displayModalitiesSelectedOptions"
      label="Modalités à afficher"
      multiple
      option-key="key"
      option-value="value"
      :items="fromParent ? displayParentModalitiesOptions : displayModalitiesOptions"
    />
  </template>
  </template>
<script>
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'DisplayPlanModalitiesOptions',
  components: {
    SelectExtended,
  },
  props: {
    planId: {
      type: [Number, String],
    },
    essai: {
      type: Object,
      default: null,
    },
    fromParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      modalitiesToIgnore: [],
      displayModalitiesSelectedOptions: [],
      displayModalitiesOptions: [],
    }
  },
  emits: ['display-plan-modalities-options'],
  watch: {
    displayModalitiesSelectedOptions() {
      this.storeDisplayPlanOptions(this.displayModalitiesSelectedOptions)
    },
  },
  mounted() {
    this.fetchService
      .get(`protocole/${this.essai.protocole.id}/modalite?sort=ordre.ASC`, {
        limit: 0,
      })
      .then((reponseModalite) => {
        this.modalite = reponseModalite.data
        this.displayModalitiesOptions = this.modalite.map((t) => ({ value: `Modalité ${t.ordre}`, key: t.id, est_enfant: t.est_enfant }))
        if (this.fromParent) {
          this.modalitiesToIgnore = this.displayModalitiesOptions.filter((m) => !m.est_enfant)
        }
        if (localStorage.getItem('displayPlanModalitiesOptions')) {
          this.setDisplayData()
        } else {
          this.displayModalitiesSelectedOptions = this.displayModalitiesOptions
        }

        if (this.fromParent) {
          this.displayModalitiesSelectedOptions = this.getDifference(this.displayModalitiesOptions, this.modalitiesToIgnore)
        }

        let off = this.getDifference(this.displayModalitiesOptions, this.displayModalitiesSelectedOptions)
        if (this.fromParent) {
          this.displayParentModalitiesOptions = this.getDifference(this.displayModalitiesOptions, this.modalitiesToIgnore)
        }
        off = off.map((o) => o.key)
        this.$emit('display-plan-modalities-options', off)
        this.isLoading = false
      })
  },
  methods: {
    storeDisplayPlanOptions(displayOptions) {
      let off = this.getDifference(this.displayModalitiesOptions, displayOptions)
      off = off.map((o) => o.key)
      let displayPlanOptionsArray = []
      const displayPlanOptions = { id: null }
      if (localStorage.getItem('displayPlanModalitiesOptions')) {
        const localDisplayPlanOptions = JSON.parse(
          localStorage.getItem('displayPlanModalitiesOptions'),
        )
        const found = localDisplayPlanOptions.find(
          (element) => element.id === this.planId,
        )

        const index = localDisplayPlanOptions.indexOf(found)
        if (index > -1) {
          localDisplayPlanOptions.splice(index, 1)
        }

        displayPlanOptionsArray = localDisplayPlanOptions
      }
      displayPlanOptions.id = this.planId
      displayPlanOptions.modalities = displayOptions.map((e) => e.key)
      displayPlanOptionsArray.push(displayPlanOptions)

      localStorage.setItem(
        'displayPlanModalitiesOptions',
        JSON.stringify(displayPlanOptionsArray),
      )
      this.$emit('display-plan-modalities-options', off)
    },

    setDisplayData() {
      if (localStorage.getItem('displayPlanModalitiesOptions')) {
        const localDisplayPlanOptions = JSON.parse(
          localStorage.getItem('displayPlanModalitiesOptions'),
        )
        const found = localDisplayPlanOptions.find(
          (element) => element.id === this.planId,
        )
        const localDisplayOptionsSelection = []
        if (found) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(found)) {
            if (key === 'modalities' && value.length > 0) {
              value.forEach((element) => {
                const f = this.displayModalitiesOptions.find(
                  (e) => parseInt(element, 10) === parseInt(e.key, 10),
                )
                if (f) {
                  localDisplayOptionsSelection.push(f)
                }
              })
            }
          }
          this.displayModalitiesSelectedOptions = localDisplayOptionsSelection
        } else {
          this.displayModalitiesSelectedOptions = this.displayModalitiesOptions
        }
      }
    },

    getDifference(array1, array2) {
      return array1.filter((object1) => !array2.some((object2) => object1.key === object2.key))
    },
  },
}
</script>
